.Tabs {
  background: #1860aa0f;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  flex: 1;
  width: 100%;
  white-space: nowrap;
  transform: scale(1);
  .scroll-area {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    padding: 0;
    height: 32px;
    & > * {
      padding: 6px 25px;
      text-decoration: none;
      color: #999;
      border: 1px solid #0000;
      display: flex;
      align-items: center;
      height: 100%;
      &.active {
        color: #3a568e;
        background: #fff;
        border-color: #f0f0f0;
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      small {
        color: #fff;
        font-weight: 600;
        font-size: 11px;
        border-radius: 12px;
        padding: 2px 9px;
        margin-left: 10px;
        &.incontact {
          background: rgba(211, 32, 121, 0.75);
        }
        &.rtm {
          background: rgba(23, 149, 181, 0.75);
        }
      }
    }
    .divider {
      flex: 1;
    }
    .end-cap {
      flex: 0 0 30px;
      padding: 0;
    }
  }
  .scroll-buttons {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0;
    border: none;
    pointer-events: none;
    button.scroll {
      pointer-events: all;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 30px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #0000;
      border-radius: 0;
      &:focus {
        outline: none;
      }
      &.left {
        left: 0;
        background-image: linear-gradient(270deg, #1860aa00 0%, rgb(241, 246, 250) 50%);
      }
      &.right {
        right: 0;
        background-image: linear-gradient(90deg, #1860aa00 0%, rgb(241, 246, 250) 50%);
      }
      svg {
        width: 100%;
        height: 100%;
        &:hover path {
          stroke: #666;
        }
        &:active path {
          stroke: #444;
        }
      }
      path {
        fill: none;
        stroke: #888;
        stroke-width: 1;
      }
    }
  }
}
