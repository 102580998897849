.Login {
  padding: 20px;
  background: #f8f8f8;
  min-height: 50vh;
  form {
    background: #fff;
    box-shadow: 0 0 4px 4px #0001;
    padding: 30px;
    .row-c {
      & > label {  
        flex: 0 0 100px;
      }
    }
  }
}
