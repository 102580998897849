* {
  box-sizing: border-box;
}

 body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.row-c {
  display: flex;
  flex-direction: row;
  align-items: center; 
}
.row-c-s {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: flex-start;
}
.row-c-c {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
}
.row-c-e {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: flex-end;
}
.row-c-b {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between;
}

.page {
  padding: 20px;
}

.page-sm {
  padding: 20px;
  max-width: 1300px;
  margin: auto;
}

input {
  color: #444;
  font-size: 15px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="text"] {
  padding: 6px 10px;
  appearance: none;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  &:focus {
    border-color: blue;
    outline: none;
  }
}
button {
  padding: 10px 30px;
  appearance: none;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: #444;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  &.primary {
    background: #3a568e;
    color: #fff;
    border: none;
  }
}
form {
  margin: auto;
  &.sm {
    max-width: 400px;
  }
  &.md {
    max-width: 600px;
  }
  h2 {
    margin: 0 0 20px 0;
  }
  h5 {
    font-weight: normal;
    color: red;
    font-size: 14px;
  }
  .row, .row-c {
    button[type="submit"] {
      flex: 1;
    }
    & > label {
      flex: 0 0 125px;
    }
    & > label + * {
      flex: 1;
    }
    & + .row, 
    & + .row-c {
      margin-top: 25px;
    }
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slide-up {
  from { transform: translateY(10px); }
  to { transform: translateY(0px); }
}