.TitleBar {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  border-bottom: 1px solid #eee;
  svg {
    width: 35px;
  }
  .back, a {
    display: flex;
    align-items: center;
  }
  a {
    padding: 0 5px;
    &:hover {
      background: #f9f9f9;
      path {
        stroke: #555;
      }
    }
  }
  path {
    fill: none;
    stroke: #999;
  }
  .title {
    margin-left: 3px;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 45px);
  }
} 
