.Grid {
  max-width: 100vw;
  overflow-x: auto;
  position: relative;
  table {
    border-collapse: collapse;
    min-width: 100%;
  }
  th {
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    font-size: 11.5px;
    text-transform: uppercase;
    color: #aab;
    white-space: nowrap;
  }
  th, td {
    padding: 5px 7px;
    max-width: 25vw;
  }
  td {
    white-space: normal;
    font-size: 14px;
  }
  tr:nth-child(even) {
    background-color: #fafafa;
  } 
  tbody.clickable tr {
    cursor: pointer;
    &:hover {
      background: #f7f7f7;
      color: #000;
    }
    &:active {
      background: #f2f2f2;
    }
  }
}