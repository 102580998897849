.Nav {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: #fcb23d;
  & > * {
    height: 100%;
  }
  .logo {
    height: 100%; 
  }
  h1 {
    font-size: 26px;
    margin-left: 15px;
  }
} 
