.Portal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  & > .background {
    width: 100%;
    height: 100%;
  }
} 
