.Card {
  border: 1px solid #eee;
  border-radius: 3px;
  & > .title {
    padding: 30px 20px;
    border-bottom: 1px solid #eee;
    background: #f7f7f7;
    font-weight: bold;
    text-align: center;
  }  
  & > .body {
    min-height: 100px;
  }
} 
