.MessageDashboard_Summary {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .Card {
    display: inline-block;
    margin: 0 20px 20px 0;
    & > .body {
      padding: 10px 0;
      max-height: 50vh;
      overflow-y: auto;
    }
  }
}